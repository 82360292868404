import {
  Component, DestroyRef, OnInit, computed, inject, input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ad6-about-segment',
  templateUrl: './ad6-about-segment.component.html',
  styleUrls: ['./ad6-about-segment.component.scss'],
  standalone: true,
})
export class Ad6AboutSegmentComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly translateService = inject(TranslateService);

  image = input<string>();
  link = input<string>();
  segment = input<string | null>(null);

  firstContent = signal<string | null>(null);
  secondContent = signal<string | null>(null);
  title = signal<string | null>(null);

  src = computed<string>(() => `assets/images/${this.image()}`);

  ngOnInit(): void {
    const titleTranslationKey = `about.title.${this.segment()}`;
    const firstContentTranslationKey = `about.content.${this.segment()}.first`;
    const secondContentTranslationKey = `about.content.${this.segment()}.second`;

    this.translateService
      .get([
        titleTranslationKey,
        firstContentTranslationKey,
        secondContentTranslationKey,
      ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (translations) => {
          this.title.set(translations[titleTranslationKey]);
          this.firstContent.set(translations[firstContentTranslationKey]);
          if (
            translations[secondContentTranslationKey] !==
            secondContentTranslationKey
          ) {
            this.secondContent.set(translations[secondContentTranslationKey]);
          }
        },
      });
  }
}
